/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@font-face {
  font-family: PT Sans;
  src: url(/assets/fonts/pt-sans.regular.ttf) format("truetype");
}

@font-face {
  font-family: PT Sans B;
  src: url(/assets/fonts/pt-sans.bold.ttf) format("truetype");
  font-weight: bold;
}
@font-face{
    font-family: FontAwsome;
    src: url(/assets/fonts/fa-solid-900.ttf) format('truetype');;

}

body{
	background: #ffffff;
    font-size: 14px;
    font-family: PT Sans
}
h1, h2, h3, h4, h5{
    margin: 10px 0 20px;
    font-family: inherit;
    font-family: PT Sans B;
    line-height: 20px;
    color: inherit;
    text-rendering: optimizelegibility;
}
h4, h5{
    color: #8E4489;
    font-size: 17.5px
}
h5{
    font-size:14px;
}

h4 a{
    color: #8E4489;
    text-decoration: none;
    text-shadow: 1px 1px 0 white;
    -webkit-transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
    transition: color 0.3s ease 0s;
}
h4 a:hover{
 color: #585E87;
 text-decoration: none; 
}
a{
	color:#585e87;
}

:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
[type="button"]::-moz-focus-inner,
{
  padding: 0;
  border-style: none;
}

.btn:focus, .btn.focus{
    box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled{
    color: #fff;
    background-color: #cecfcc;
    border-color: #dcdddb;
}
.form-control:focus{
    border-color: rgba(172, 68, 138, 0.6);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(142 68 138 / 60%)!important;
}
.header-back{
	background: #5c618721;
	color:#585e87;
}
.header-back .title{
    font-size: 20px;
    text-transform: uppercase;
}

.signOutbtn{
	cursor: pointer;
	transition:background .2s;
}
.signOutbtn:hover{
	background: #585e87;
    color: white;

}

.center_box{
	display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    
}
.list_grid{
	padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-top: 1px solid #eaebef;
}

.list_grid .del{
    cursor: pointer;
}
.list_grid_title{
	background: #eaebef;
}

.list-group-item{
	padding:0.75rem 10.25em;
}

.btn{
	background-color: #585e87;
	border-color: #585e87;
}
.dashboard_title{
	font-size: 16px;
    text-transform: uppercase;
    margin: 37px 0px;
    color: #585e87;
    
}
.chart_add{
	
    width: 40px;
    height: 40px;
    background: #585e87;
    color: white;
    border: none;

}
span.chart_add{
    text-align: center;
    display: inline-block;
    line-height: 3;
    cursor: pointer;
}
.chart_help{
	color: grey;
    font-size: 12px;
    display: block;
}
.series{
	background-color: #eaebef;
  padding: 20px;
  margin: 25px 0;
  
}
.invalid-feedback{
	border-top: 1px solid red;
}

.respo{
    width: 230px;
    color: white;
    text-align: center;
}
.fail{
    background: red;
}
.succ{
   background: #1ebb1e;
}
.mandatory_line{
	border-top: 1px solid #dc3545;
	color: #dc3545;
	font-size: 80%;
}
.category_box{
	height: 100px;
    overflow: overlay;
    width: 100%;
    padding: 5px;
    border: 1px solid #585e87;
    display: inline-block;
}
.nav-inside{
	margin-right: 10px;
    padding: 10px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 14px;
}
.my_select, .embeded_graph, .row_data{
    width: 100%;
    max-width: 320px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    background: white;
    line-height: 3;
    border-radius: 5;
    border: 0;
    cursor: pointer;
}
select::-ms-expand {
   display: none;
}

.selectdiv:after {
  content: '▼';
  right: 11px;
  top: 12px;
  padding: 0 8px 6px;
  position: absolute;
  pointer-events: none;
  color: #8c4388;
}

.embeded_graph fa-icon, .row_data fa-icon{
    color:#8c4388;
    margin-right: 10px;
}

share-buttons.pt-5{
    margin-top: 0px!important;
    padding-top: 5px!important;
    border-radius: 11px;
}
.sb-wrapper{
    min-width: 0!important;
    font-size: 11px!important;
    color:#8c4388;
}

.selectdiv{
    position: relative;
}


.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  
  display: block;
  
  float: right;
  
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

.graph_footer{
    padding: 2px;
    background: #D6D6D6;
    border: 1px solid #CCCEC2;
    display: block;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 1px 0 rgb(255 255 255 / 20%) inset, 0 1px 2px rgb(0 0 0 / 5%);

}


/*frontend*/
nav{
	margin: 86px auto 0 auto;
}

nav li a{
    padding: 10px 0.5rem!important;
    color: #585E87!important
}


nav li a:hover{
    color: #F79C88!important;
    text-decoration: none;
}

body{
    background-color: #F7F7F7;
}
.logo{
    background: url(/assets/img/logo.png) no-repeat scroll 0 0 transparent;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 538px;
    z-index: 0;
    display: inline-block;
    height: 405px;
    left: -120px;
    cursor: pointer;
    z-index: 1000
}

.right_img{
    background: url(/assets/img/deco-3.png) no-repeat scroll right top transparent;
    display: inline-block;
    height: 218px;
    margin: 0px auto;
    position: absolute;
    right: -25px;
    top: -7px;
    width: 250px;
}

.my_btn{
    background: url(/assets/img/search-btn.png) no-repeat;
    height: 58px;
    width: 43px;
    position: relative;
    display: inline-block;
    margin-top: 5px;
    top: 10px;
    right: 20px;
    position: relative;
    cursor: pointer;

}

.my_btn span{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(/assets/img/search-btn.png) no-repeat;
    background-position: 2px -61px;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
}
.my_btn:hover span {
    opacity: 1;
}

input[type="text"]{
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
    -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
    -o-transition: border linear 0.2s, box-shadow linear 0.2s;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    display: inline-block;
    padding: 4px 6px;
    font-size: 14px;
    line-height: 20px;
    color: #555555;
    vertical-align: middle;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

}


/*socil media links*/
.media-links{
    position: absolute;
    left: -84px;
    top:10px;
}
.media-links a.fb {
    display: block;
    background: url("/assets/img/fb.png") 0 0 no-repeat; 
    width: 33px;
    height: 32px;
    position: absolute;
    float: left;
}

.media-links a.fb span{
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: url("/assets/img/fb.png") 0 -32px no-repeat; 
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition:    opacity 0.5s;
    -o-transition:      opacity 0.5s;
}

.media-links a.fb:hover span, .contact-us-wrap a.fb:hover span, .contact-us-wrap a.tw:hover span {
    opacity: 1;
}


.media-links a.gp {
    display: block;
    background: url("/assets/img/gp.png") 0 0 no-repeat; 
    width: 30px;
    height: 27px;
    float: left;
    position: absolute;
    margin: 5px 0 0 35px;
}


.media-links a.gp span {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: url("/assets/img/gp.png") 0 -26px no-repeat; 
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition:    opacity 0.5s;
    -o-transition:      opacity 0.5s;
}

.media-links a.gp:hover span {
    opacity: 1;
}

.media-links a.tw {
    display: block;
    background: url("/assets/img/tw.png") 0 0 no-repeat; 
    width: 30px;
    height: 31px;
    float: left;
    position: absolute;
    margin: 28px 0 0 12px;
}

.media-links a.tw span {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: url("/assets/img/tw.png") 0 -30px no-repeat; 
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition:    opacity 0.5s;
    -o-transition:      opacity 0.5s;
}

.media-links a.tw:hover span, .contact-us-wrap a.tw:hover span {
    opacity: 1;
}

.media-links a.gh {
    display: block;
    background: url("/assets/img/gh.png") 0 0 no-repeat; 
    width: 40px;
    height: 40px;
    float: left;
    position: absolute;
    margin: 30px 0 0 43px;
}

.media-links a.gh span {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: url("/assets/img/gh.png") 0 -40px no-repeat; 
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition:    opacity 0.5s;
    -o-transition:      opacity 0.5s;
}

.media-links a.gh:hover span {
    opacity: 1;
}

/*home*/
.home {
    width: 540px;
    margin-top: 100px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}
h3.main-title{
    text-align: center;
    text-transform: uppercase;
    color: #8F448A;
    text-shadow: 1px 1px 0 white;
    margin:25px 0px;
}
h4.main-title{
    text-align: center;
    text-transform: uppercase;
    color: #82847B;
    text-shadow: 1px 1px 0 white;
}
.textjustify{
    text-align: justify;
}

.block {
    display: inline-block;
    height: 153px;
    position: relative;
    width: 153px;
    position: relative;
    z-index: 100;
    width: 153px;
    margin-top: 45px;
}

.homeCircle{
    background: url(/assets/img/topic-bck.png) 0 0 no-repeat;
}

.catCircle{
    background: url(/assets/img/lilac-bck.png) 0 0 no-repeat;
}

.block  > a{
    
    display: table;
    color: #FED089;
    text-decoration: none;
    -webkit-transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
    transition: color 0.3s ease 0s;
    font-weight: bold;
    float: left;
    font-size: 17px;
    margin: 0 auto;
    width: 100%;
    height: 153px;
    text-align: center;
}

.block a span{
    display: table-cell;
    vertical-align: middle;
    text-align: center;

}


#politics .icon, #family .icon, #economy .icon,
#youth .icon,
#urban .icon,
#health .icon,
#education .icon,
#social_welfare .icon,
#other .icon {
    width: 59px;
    height: 60px;
    display: block;
    margin: 0px auto 0 auto;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    position: relative;
    z-index: -1;
    visibility: hidden;
    text-align: center;
}
#youth .icon, #education .icon{
    width: 77px!important;
}
#urban .icon{
    width: 84px;
}



#politics:hover .icon,
#family:hover .icon, 
#economy:hover .icon,
#youth:hover .icon,
#urban:hover .icon,
#health:hover .icon,
#education:hover .icon,
#social_welfare:hover .icon,
#other:hover .icon {
    margin: -52px auto 0 auto;
    z-index: -1;
    visibility: visible;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;

}

#politics:hover  a span,
#family:hover  a span, 
#economy:hover  a span,
#youth:hover  a span,
#urban:hover a span,
#health:hover  a span,
#education:hover a span,
#social_welfare:hover a span,
#other:hover  a span{
    color:white!important;
}

#politics .icon{
    background: url(/assets/img/politics.png) no-repeat;
}
#family .icon{
   background: url(/assets/img/family.png) no-repeat;
   
}
#economy .icon{
   background: url(/assets/img/economy.png) no-repeat;
   
}
#youth .icon{
   background: url(/assets/img/youth.png) no-repeat;
   
}
#urban .icon{
   background: url(/assets/img/urban.png) no-repeat;
   
}
#health .icon{
   background: url(/assets/img/health.png) no-repeat;
   
}
#education .icon{
   background: url(/assets/img/education.png) no-repeat;
   
}
#social_welfare .icon{
   background: url(/assets/img/social_welfare.png) no-repeat;
   
}
#other .icon{
/*     background: url(/assets/img/topic-bck.png) no-repeat;
*/   
}

.view-all{
    margin:0 auto;
    text-align: center;
    margin: 40px 0px
}
/*footer*/
.footer{
    background: url(/assets/img/dashed-double.png) repeat-x white;
    padding-top: 12px;
    
}
.reactor{
    
    background: url(/assets/img/logos/reactor.svg) no-repeat;
    background-size: contain;
}
.osf{
    background: url(/assets/img/logos/logo-osf.png) no-repeat;
    background-size: contain;
    
}
.usaid{
    background: url(/assets/img/logos/logo-usaid.png) no-repeat;
    background-size: contain;
}
.foom{
    background: url(/assets/img/logos/logo-foom.png) no-repeat;
    background-size: contain;
}

.envelope, .weblink, .twicon, .fbicon{
    font-family: FontAwesome;
    padding-right: 10px !important;
    vertical-align: middle;
}

.envelope:before{
    content: "\f0e0"; 
    
}
.weblink:before{
    content: "\f0c1"; 
    
}
.twicon:before{
    content: "\f099"
}
.fbicon:before{
    content: "\f09a"
}


.reactor, .osf, .usaid, .foom{
    width: 100%;
    height: 50px;
}

.footer {
    font-size: 11px;
    margin-top: 60px;

}


.category .row{
    align-items: center;
    justify-content: center;
}
.contact{
    width: 300px;
    margin:0 auto;
}
.footer .row, .category .row, .research .row, .search .row {
    padding: 5px 0px;
    /*align-items: center;
    justify-content: center;*/
}

.footer .cc{
    background: url(/assets/img/dashed-single.png) repeat-x white;
    padding-top: 20px;
    margin: 0 auto;
    text-align: center;
}
.footer .licence{
    padding: 10px 0px;
}

.footer-deco-right{
    background: url(/assets/img/footer-deco-right.png) no-repeat;
    width: 112px;
    height: 60px;
    margin: auto;
    display: block;
    vertical-align: bottom;
}

.block .backImage{
    background: url(/assets/img/lilac-bck.png) 0 0 no-repeat;
    display: table;
    color: #FED089;
    text-decoration: none;
    -webkit-transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
    transition: color 0.3s ease 0s;
    font-weight: bold;
    float: left;
    font-size: 17px;
    margin: 0 auto;
    width: 153px;
    height: 153px;
    text-align: center;
    text-shadow: none;

}
/*research */
.boxImage{
    width: 134px;
    height: 134px;
    position: relative;
    display: inline-block;
    -webkit-border-radius: 79px;
    -moz-border-radius: 79px;
    -o-border-radius: 79px;
    border-radius: 79px;
    border: 8px solid #C0C3B5;
    overflow: hidden;
    -webkit-transition: color 0.7s ease 0s;
    -moz-transition: color 0.7s ease 0s;
    -o-transition: color 0.7s ease 0s;
    transition: color 0.7s ease 0s;
    margin-right: 5%;
    clear: both;
    
    background-size: 95%;
    background-repeat: no-repeat;
    background-position: center;
    background-color:white;
}

.more a{
    color: #585D86;
    text-decoration: none;
}
.more a:hover{
    text-decoration: none;
    color:#8E448A;

}

.breadcrumbs {
    display: block;
    min-height: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
    position: relative;
    z-index: 1000;
}
.breadcrumbs a{

    color: #585D86;
    background: none repeat scroll 0 0 rgb(225, 225, 214);
    display: block;
    float: left;
    height: 23px;
    padding-left: 5px;
    text-decoration: none;
}
.breadcrumbs a:hover{
    color:#8E448A
}

.breadcrumbs a span{
    background: url('/assets/img/breadcrumb.png') no-repeat scroll right top transparent;
    display: block;
    line-height: 13px;
    padding: 5px 17px 5px 6px;
}
.breadcrumbs a:last-child {
    display: block;
    float: left;
    height: 23px;
    padding-left: 5px;
    text-decoration: none;
    color: #8E448A;
    background: url('/assets/img/breadcrumbs.png');
    background: -moz-linear-gradient(left, rgba(225,226,215,1) 37%, rgba(240,241,235,1) 38%, rgba(255,255,255,0.98) 39%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(37%,rgba(225,226,215,1)), color-stop(38%,rgba(240,241,235,1)), color-stop(39%,rgba(255,255,255,0.98)), color-stop(100%,rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(225,226,215,1) 37%,rgba(240,241,235,1) 38%,rgba(255,255,255,0.98) 39%,rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(225,226,215,1) 37%,rgba(240,241,235,1) 38%,rgba(255,255,255,0.98) 39%,rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, rgba(225,226,215,1) 37%,rgba(240,241,235,1) 38%,rgba(255,255,255,0.98) 39%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(225,226,215,1) 37%,rgba(240,241,235,1) 38%,rgba(255,255,255,0.98) 39%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e2d7', endColorstr='#00ffffff',GradientType=1 );
}
.researcher-icon{
    background: url(/assets/img/icons/icons.png) -8px -88px no-repeat;
}
.abstract-icon{
    background: url(/assets/img/icons/icons.png) -8px -8px no-repeat;
}
.metodology-icon{
    background: url(/assets/img/icons/icons.png) -8px -48px no-repeat;
}
.data-viz-icon{
    background: url(/assets/img/icons/icons.png) -8px -128px no-repeat;
}
.icon-size{
    width: 36px;
    height: 36px;
    margin-right: 10px;
    display: inline-block;
}
.researcher-box, .abstract-box, .metodology-box, .data-viz-box{
    display: inline-flex;
    align-items: center;
    justify-content: left;

}

div[class$="_block"]{
    margin-bottom: 30px;
}
.researcher_block .text .a:after{
    /*content:', ';*/
}
.researcher_block .text .a:last-child:after{
    content:'';
} 
h4.title{
    margin-bottom: 30px
}
.download-research {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 30px 0;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
}
.purple-btn{
    font-family: PT Sans, sans-serif;
    margin: 0 auto;
    line-height: 20px;
    color: rgb(51, 51, 51);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #585E87;
    *background-color: #585E87;
    *border: 0;
    color: white;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    *zoom: 1;
    -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
}

.grey-btn{
    font-family: PT Sans, sans-serif;
    margin: 0 auto;
    line-height: 20px;
    color: rgb(51, 51, 51);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #96998F;
    *background-color: #96998F;
    *border: 0;
    color: white;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    *zoom: 1;
    -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
    -o-transition: background 0.3s ease-in;
    -ms-transition: background 0.3s ease-in;
    -moz-transition: background 0.3s ease-in;
    -webkit-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
    border:0;
}

.grey-btn:hover {
    background: #595D86;
    color:white;
}
.btn-primary:hover{
    background: #595D86;
    color:white;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
    background: #595D86;
    border-color: 1px solid #595D86;
}
.prev_next{
    margin:30px 0px;
}
.tooltip-inner{
    background: #585E87!important;
    color: white;
}
.tooltip .arrow:before {
    border-top-color: #585E87!important;
}

.tooltip .tooltip-inner {
    background-color: #585E87;
}
.graph-title{
    margin-bottom: 0!important;
}
.graph-desc{
    text-align: justify;
}
.modal-header h4{
    margin-bottom: 10px
}

.modal-body .message{
    width: 300px;
    margin:0 auto;
}
.modal-body .message textarea{
    min-height: 200px
}

button.more_links, button.manual_btn, a.more_links{
    margin: 15px 0;

}
button.more_links{
    width: 100%;

}
.desc_{
    text-align: justify;
    display: block;
}
.text{
    text-align: justify;
}

.info:hover .my_tooltip{
    visibility:visible;
    z-index: 1000;

}
.highcharts-legend-item{
    font-family: PT Sans B;
}
.my_tooltip{
    color: white;
    font-size: 14px;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: -115px;
    z-index: 100;
    background: #585D86;
    border: 1px solid #585D86;
    outline: 0;
    outline: thin dotted \9;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(88 93 134 / 60%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(88, 93, 134, 0.6);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(88 93 134 / 60%);
    padding: 0px 15px 15px 15px;
    position: absolute;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    /*display: none;*/
    width: 500px;
    visibility: hidden;
    text-align: justify;



}
.my_tooltip:after{
    border-color: #585D86 transparent transparent transparent;
    border-style: solid;
    border-width: 9px;
    content: "";
    display: block;
    height: 0;
    right: 50%;
    position: absolute;
    top: 100%;
    width: 0;
}
.my_tooltip h5{
    color:white;
}

.graph-info{
    width: 20px;
    text-align: center;
    position: absolute;
    z-index: 1000;
    top: 71px;
    right: 26px;
    cursor: pointer;
    border-radius: 50%;
}
.graph-img{
    max-width: 100%;
}

/*search*/
.searchBox{
    margin-top:50px;
    margin-bottom: 20px
}
.search_part{
    text-align: left;
    text-transform: uppercase;
    color: #8f448a;
    text-shadow: 1px 1px 0 white;
    font-size: 22px
}

#search_research, #search_graph{
    margin-bottom: 20px;
    border-bottom: 1px solid #d8d9d0;
    padding-bottom: 20px;
}


/*sidebar*/
.sidebar ul {
    list-style: none
}
.sidebar li{
    position: relative;
}
.sidebar nav li,  nav.navbar .nav-item{

    font-weight: bold;
    font-size: 17px;
    display: inline-block;
    position: relative;
    font-family: PT Sans B;
}

.sidebar_img{
    width: 35px;
    height: 35px;
    position: absolute;
    left:0;
}
.sidebar_img_trans{
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
}

#politics:hover #hpol,
#family:hover #hfam,
#economy:hover #heco,
#youth:hover #hyou,
#urban_development:hover #hurb,
#economy:hover #heco,
#health:hover #hheal,
#education:hover #hedu,
#wellfare:hover #hwell,
#other:hover #hoth
{
  opacity: 1;  
}



#politics:hover .parent{
    color:#585D86
}

#pol{
    background:url('/assets/img/icons/politics.png');
    
}
#pol::before, #fam::before,#eco::before,
#you::before,
#urb::before,
#eco::before,
#heal::before,
#edu::before,
#well::before,
#oth::before{
  background: url(/assets/img/icons/arrows.png);
  content: '';
  position: absolute;
  top: 10px;
  left: -15px;
  width: 13px;
  height: 13px;
  background-position: 0px 0px;  
}
#hpol::before,#hfam::before,#heco::before,
#hyou::before,
#hurb::before,
#heco::before,
#hheal::before,
#hedu::before,
#hwell::before,
#hoth::before{
    background: url(/assets/img/icons/arrows.png);
    content: '';
    position: absolute;
    top: 10px;
    left: -15px;
    width: 13px;
    height: 13.9px;
    background-position: 0px 36px;
}
#hpol{
 opacity: 0;
 background:url('/assets/img/icons/hpolitics.png');
 z-index: 1000;
}

#fam{
    background:url('/assets/img/icons/family.png');
    
}
#hfam{
 opacity: 0;
 background:url('/assets/img/icons/hfamily.png');
 z-index: 1000;
}
#eco{
    background:url('/assets/img/icons/economy.png');
    
}
#heco{
 opacity: 0;
 background:url('/assets/img/icons/heco.png');
 z-index: 1000;
}

#you{
    background:url('/assets/img/icons/youth.png');
    
}
#hyou{
 opacity: 0;
 background:url('/assets/img/icons/hyouth.png');
 z-index: 1000;
}
#urb{
    background:url('/assets/img/icons/urban.png');
    
}
#hurb{
 opacity: 0;
 background:url('/assets/img/icons/hurban.png');
 z-index: 1000;
}
#eco{
    background:url('/assets/img/icons/economy.png');
    
}
#heco{
 opacity: 0;
 background:url('/assets/img/icons/heconomy.png');
 z-index: 1000;
}
#heal{
    background:url('/assets/img/icons/health.png');
    
}
#hheal{
 opacity: 0;
 background:url('/assets/img/icons/hhealth.png');
 z-index: 1000;
}
#edu{
    background:url('/assets/img/icons/edu.png');
    
}
#hedu{
 opacity: 0;
 background:url('/assets/img/icons/hedu.png');
 z-index: 1000;
}
#well{
    background:url('/assets/img/icons/wellfare.png');
    
}
#hwell{
 opacity: 0;
 background:url('/assets/img/icons/hwellfare.png');
 z-index: 1000;
}
#oth{
    background:url('/assets/img/icons/other.png');
    
}
#hoth{
 opacity: 0;
 background:url('/assets/img/icons/hother.png');
 z-index: 1000;
}

.sidebar{
    margin-left: 55px
}


.sidebar a{
    color: rgb(130, 132, 123);
    text-decoration: none;
    text-shadow: 1px 1px 0 white;
    -webkit-transition: color 0.3s ease 0s;
    -moz-transition: color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s;
    transition: color 0.3s ease 0s;
}
.sidebar .parent{
    position: relative;
    display: block;
    background-position: left top;
    height: 35px;
    vertical-align: middle;
    background-repeat: no-repeat;
    padding: 8px 0 0 23px;
    margin: 0 0 7px 18px; 
    font-size: 17px;
    font-family: PT Sans B;
    margin-left: 18px;
    color: rgb(130, 132, 123);
}
.sidebar li{
    cursor: pointer
}
.sidebar li:hover .parent{
    color:#585D86;
}
.sidebar .parent :hover{
    color:#585D86
}
.sidebar ul li.child a:hover{
    color:#585D86
}

.sidebar .child{
    height: 20px;
    padding: 0px 0px 0px 4px;
    font-family: PT Sans B;
    font-size: 15px;
    position: relative;
}

ul[id^="child_"]{
    display: none;
}
.open_sidebar{
    display: block!important
}
.open_opacity{
    opacity: 1!important;
}
.open_color a, .open_color{
    color:#585D86!important
}

.angular-editor-wrapper{
    background: white;
}

/*end*/

/*media queries*/
@media (min-width: 1200px){
    .category, .page{
        width: 960px;
    }
}

@media (max-width: 979px){

    .logo {
        background-image: url(/assets/img/logo-circle.png) !important;
        background-repeat: no-repeat;
        z-index: 0;
        margin: 0 auto;
        background-position: center top;
        height: 300px;
        margin: 0 auto;
        position: relative;
        left: 0;
        right: 0;
        z-index: 0;
        display: block;
        width: auto;
        z-index: 1000;
    }
    .media-links{
        display: none;
    }
    .right_img{
        display: none;
    }
    nav{
        margin: 0;
    }

    .navbar-light{
        min-height: 40px;
        padding-right: 20px;
        padding-left: 20px;
        background-color: #fafafa;
        background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
        background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
        background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
        background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
        background-repeat: repeat-x;
        border: 1px solid #d4d4d4;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
        *zoom: 1;
        -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
        -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
        box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
    }
    .reactor, .osf, .usaid, .foom{
        width: 50%;
        margin: 0 auto;
    }
    @media (max-width: 480px) {
        h3.main-title{
            line-height: 1;
        }
        .home{
            width: 100%;
        }
        .boxI{
            display: block;
            width: 100%;
            text-align: center;
        }
        .my_select, .embeded_graph, .row_data{
            max-width: 100%;
        }

    }
}